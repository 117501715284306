import * as React from "react"
import classNames from "classnames"
import { arrow, previous } from "./Arrow.module.css"

const Arrow = ({ prev }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 24 24"
      className={classNames(arrow, {
        [previous]: !!prev,
      })}
    >
      <path d="M22 12l-8 6 1 1 9-8-9-7-1 1 8 6H0v1h22z" />
    </svg>
  )
}

export const NextArrow = Arrow
export const PreviousArrow = () => <Arrow prev={true} />
