import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import classNames from "classnames"
import { Layout } from "../layouts/default"
import { Card, BlogCard } from "../components/Card"
import { Container } from "../components/Container"
import { Mail, Linkedin, Instagram, Phone } from "../components/Icons"
import { Headline } from "../components/Headline"
import { HeadlineButton } from "../components/HeadlineButton"
import { RoundedButton } from "../components/RoundedButton"
import { SectionHeader, Title } from "../components/SectionHeader"
import Image from "gatsby-image"
import { Slider, col1, mdCol2, lgCol3 } from "../components/Slider"
import { cleanSrcSet } from "../lib/utils"
import {
  flex,
  flexWrap,
  flexAlignLeft,
  fullWidth,
  hidden,
  inlineBlock,
  justifyBetween,
  marginBottom,
  marginBottomLarge,
  marginLeftHalf,
  marginRight,
  marginTop,
  mdBlock,
  overflowHidden,
  textCenter,
  fontBold,
  mdFlex,
  rounded,
  relative,
  removeFocus,
} from "../components/utils.module.css"
import {
  blogListing,
  cardWrap,
  contactIcon,
  contactLabel,
  contactLink,
  sectionSpacing,
  socialWrapper,
  personItem,
  activeItem,
} from "./index.module.css"
import { masthead, mastheadImage } from "../components/masthead.module.css"

const ProjectsSection = ({ projects, ...props }) => {
  const sliderItems = projects.map((project, index) => {
    return (
      <Card
        key={project.id}
        title={project.customer.name}
        subtitle={project.project}
        description={project.description}
        background={
          project.background &&
          cleanSrcSet(project.background.childImageSharp.fluid)
        }
        className={fullWidth}
      />
    )
  })

  return (
    <Container as="section" {...props}>
      <SectionHeader
        title="Projects"
        subtitle="These are the things we build."
      />

      <Slider
        className={marginTop}
        columns={[
          [1, col1],
          [2, mdCol2],
          [3, lgCol3],
        ]}
        controls={[true]}
        items={sliderItems}
      />
    </Container>
  )
}

const ClientSection = ({ testimonials, ...props }) => {
  const [selectedTestimonial, setSelectedTestimonial] = React.useState(
    testimonials[0]
  )

  const selectedIndex = testimonials.findIndex(
    (testimonial) => testimonial === selectedTestimonial
  )

  const sliderItems = testimonials.map((testimonial) => {
    return (
      <Card
        key={testimonial.id}
        title={testimonial.name}
        subtitle={testimonial.job}
        description={testimonial.description}
        className={fullWidth}
      />
    )
  })

  return (
    <Container as="section" {...props}>
      <SectionHeader
        title="How do our clients think we do?"
        subtitle="This is what they have to say."
      />
      <div className={`${flex} ${justifyBetween} ${marginTop}`}>
        <div className={`${justifyBetween} ${flexWrap} ${hidden} ${mdFlex}`}>
          {testimonials.map((testimonial, index) => {
            return (
              <button
                key={testimonial.id}
                onClick={() => setSelectedTestimonial(testimonial)}
                className={`${marginBottom} ${marginRight}`}
              >
                <Image
                  fixed={testimonial.image.fixed}
                  className={classNames(rounded, personItem, {
                    [activeItem]: selectedIndex === index,
                  })}
                />
                <Title as="h3">{testimonial.firstname}</Title>
                <p>{testimonial.job}</p>
              </button>
            )
          })}
        </div>
        <div className={`${overflowHidden} ${cardWrap}`}>
          <Slider
            animate={[true, false]}
            controls={[true, false]}
            columns={[[1, col1]]}
            items={sliderItems}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>
    </Container>
  )
}

const BlogSection = ({ posts, ...props }) => {
  const cards = []
  for (let i = 0; i < 3; i++) {
    if (posts[i]) {
      cards.push(
        <BlogCard
          key={posts[i].fields.slug}
          slug={posts[i].fields.slug}
          teaserImage={
            posts[i].frontmatter.heroimage.desktop.childImageSharp.fixed
          }
          title={posts[i].frontmatter.title}
          category={posts[i].frontmatter.category}
          excerpt={posts[i].excerpt}
          className={i > 0 ? `${hidden} ${mdBlock}` : null}
        />
      )
    } else {
      cards.push(<div />)
    }
  }

  return (
    <Container as="section" {...props}>
      <SectionHeader title="Blogs" subtitle="Up-to-date on technology" />
      <div className={`${blogListing} ${marginTop}`}>{cards}</div>
      <RoundedButton
        as={Link}
        to="/blog"
        narrow={true}
        variation="outline"
        className={`${inlineBlock} ${marginTop}`}
      >
        See all
      </RoundedButton>
    </Container>
  )
}

const VacanciesSection = ({ vacancies, siteMetadata, ...props }) => {
  const sliderItems = vacancies.map((vacancy, index) => {
    return (
      <Card
        key={vacancy.id}
        title={vacancy.job}
        subtitle={vacancy.timeago}
        description={vacancy.description}
        background={
          vacancy.background &&
          cleanSrcSet(vacancy.background.childImageSharp.fluid)
        }
        data-datetime={vacancy.date}
        className={fullWidth}
        footer={
          <div className={textCenter}>
            <a
              href={`mailto:${siteMetadata.emails.contact}`}
              className={contactLink}
            >
              <Mail className={contactIcon} />
              <span className={contactLabel}>Get in touch</span>
            </a>
          </div>
        }
      />
    )
  })

  return (
    <Container as="section" {...props}>
      <SectionHeader
        title="Join the force"
        subtitle="Do you have what it takes?"
      />

      <Slider
        className={marginTop}
        columns={[
          [1, col1],
          [2, mdCol2],
          [3, lgCol3],
        ]}
        controls={[true]}
        items={sliderItems}
      />
    </Container>
  )
}

function findDefaultSelectedEmployeeForPage(employees, pageContextEmployee) {
  var selectedEmployee = employees[0]

  if (!!pageContextEmployee) {
    const getEmployeeFromList = employees.find(employee => {
      if (employee.firstname !== pageContextEmployee.firstname) {
        return false
      } else if (employee.lastname !== pageContextEmployee.lastname) {
        return false
      }

      return true
    })

    selectedEmployee = getEmployeeFromList
  }

  return selectedEmployee
}

const WhoAreWeSection = ({ employees, pageContextEmployee, ...props }) => {
  var [selectedEmployee, setSelectedEmployee] = React.useState(findDefaultSelectedEmployeeForPage(employees, pageContextEmployee))

  const selectedIndex = employees.findIndex(
    (employee) => employee === selectedEmployee
  )

  const sliderItems = employees.map((employee) => {
    return (
      <Card
        key={employee.id}
        title={employee.name}
        subtitle={employee.job}
        description={employee.description}
        className={fullWidth}
      />
    )
  })


  return (
    <Container as="section" {...props}>
      <SectionHeader
        title="Who are we?"
        subtitle="Team of highly-skilled technical masters"
      />

      <div
        className={`${flex} ${justifyBetween} ${flexAlignLeft} ${marginTop}`}
      >
        <div className={`${flexAlignLeft} ${flexWrap} ${hidden} ${mdFlex}`}>
          {employees.map((employee, index) => {
            return (
              <button
                key={employee.id}
                onClick={(ev) => {
                  window.history.replaceState('', '', `/employee/${employee.firstname}-${employee.lastname}#who-are-we`);
                  setSelectedEmployee(employee)
                }}
                className={`${marginBottom} ${marginRight} ${removeFocus}`}
              >
                <Image
                  fixed={employee.image.fixed}
                  className={classNames(rounded, personItem, {
                    [activeItem]: selectedIndex === index,
                  })}
                />
                <Title as="h3">{employee.firstname}</Title>
                <p>{employee.job}</p>
                <div className={`${flex} ${socialWrapper}`}>
                  {employee.social.map(({ type, value }, index) => {
                    const className = index > 0 ? marginLeftHalf : null
                    if (type === "linkedin") {
                      return (
                        <Linkedin
                          key={type}
                          value={value}
                          className={className}
                        />
                      )
                    }
                    if (type === "instagram") {
                      return (
                        <Instagram
                          key={type}
                          value={value}
                          className={className}
                        />
                      )
                    }
                    if (type === "phone") {
                      return (
                        <Phone key={type} value={value} className={className} />
                      )
                    }

                    return null
                  })}
                </div>
              </button>
            )
          })}
        </div>

        <div className={`${overflowHidden} ${cardWrap}`}>
          <Slider
            animate={[true, false]}
            controls={[true, false]}
            columns={[[1, col1]]}
            items={sliderItems}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>
    </Container>
  )
}

export default function HomePage({ data, pageContext }) {
  
  const mastheadImg = data.masthead.nodes[0].childImageSharp.fluid
  const siteMetadata = data.allSite.nodes[0].siteMetadata

  return (
    <Layout siteMetadata={siteMetadata}>
      <div className={relative}>
        <Image
          fluid={cleanSrcSet(mastheadImg)}
          loading="eager"
          className={mastheadImage}
        />
        <Container
          className={relative}
          style={{
            maxHeight: mastheadImg.presentationHeight,
            height: "60vh",
          }}
        >
          <div className={`${flex} ${masthead} ${fontBold}`}>
            <Headline className={marginBottomLarge}>
              A motivated team of highly skilled developers, we are.
            </Headline>

            <HeadlineButton
              as="a"
              href={`mailto:${siteMetadata.emails.contact}`}
            >
              Let’s work together ✉
            </HeadlineButton>
          </div>
        </Container>
      </div>

      <ProjectsSection
        id="projects"
        projects={data.projects.nodes}
        className={sectionSpacing}
      />

      {/* <ClientSection
        testimonials={data.testimonials.nodes}
        id="clients"
        className={sectionSpacing}
      /> */}

      {/* <VacanciesSection
        vacancies={data.vacancies.nodes}
        id="join-the-force"
        className={sectionSpacing}
        siteMetadata={siteMetadata}
      /> */}

      {/* <BlogSection
        posts={data.posts.nodes}
        id="blog"
        className={sectionSpacing}
      /> */}

      <WhoAreWeSection
        id="who-are-we"
        employees={data.employees.nodes}
        pageContextEmployee={pageContext.selectedEmployee}
        className={sectionSpacing}
      />
      <div className={sectionSpacing} />

      <Container>
        <HeadlineButton
          variation="outline"
          as="a"
          href={`mailto:${siteMetadata.emails.contact}`}
        >
          Let’s work together ✉
        </HeadlineButton>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query IndexPage {
    ...MetaDataQuery
    masthead: allFile(filter: { name: { eq: "homepage-masthead-desktop" } }) {
      nodes {
        childImageSharp {
          fluid(
            maxHeight: 775
            srcSetBreakpoints: [450, 615, 775]
            toFormat: JPG
          ) {
            ...GatsbyImageSharpFluid_withWebp
            presentationHeight
          }
        }
      }
    }
    employees: allEmployee {
      nodes {
        id
        name
        firstname
        lastname
        job
        description
        social {
          value
          type
        }
        image {
          fixed(
            width: 152
            height: 152
            background: "transparent"
            toFormat: JPG
          ) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
    projects: allProject(sort: { order: ASC, fields: order }) {
      nodes {
        id
        customer {
          name
        }
        project
        description
        background {
          childImageSharp {
            fluid(maxWidth: 400, srcSetBreakpoints: [400], toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    posts: allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 3) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          category
          title
          heroimage {
            desktop {
              childImageSharp {
                fixed(
                  width: 373
                  height: 453
                  fit: COVER
                  cropFocus: CENTER
                  toFormat: JPG
                ) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
        excerpt
      }
    }
    # testimonials: allTestimonial {
    #   nodes {
    #     id
    #     firstname
    #     name
    #     job
    #     description
    #     customer {
    #       name
    #     }
    #     image {
    #       fixed(
    #         width: 152
    #         height: 152
    #         background: "transparent"
    #         toFormat: JPG
    #       ) {
    #         ...GatsbyImageSharpFixed_withWebp
    #       }
    #     }
    #   }
    # }
    # vacancies: allVacancy {
    #   nodes {
    #     id
    #     job
    #     date(formatString: "YYYY-MM-DD[T]HH:mm:ss.SSSZ")
    #     timeago: date(fromNow: true)
    #     description
    #     background {
    #       childImageSharp {
    #         fluid(maxWidth: 400, srcSetBreakpoints: [400]) {
    #           ...GatsbyImageSharpFluid_withWebp
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
