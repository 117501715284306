import * as React from "react"
import classNames from "classnames"
import {
  controls as controlsClass,
  controlsActive,
  controlsButton,
  controlsClassTop,
  glide,
  glideSlide,
  glideSlider,
  glideSlides,
} from "./index.module.css"
import { overflowHidden, textDark, relative } from "../utils.module.css"
import { PreviousArrow, NextArrow } from "./Arrow"
export { col1, mdCol2, lgCol3 } from "./index.module.css"

const normalizeValues = (value) => {
  const res = [...value]
  if (res.length === 1) {
    res.push(res[0])
    res.push(res[0])
  }
  if (res.length === 2) {
    res.push(res[1])
  }

  return res
}

const getViewportValue = (value) => {
  const newVal = normalizeValues(value)

  if (window.matchMedia("(min-width: 1200px)").matches) {
    return newVal[2]
  }
  if (window.matchMedia("(min-width: 768px)").matches) {
    return newVal[1]
  }

  return newVal[0]
}

const hasTouchCapabilities = () => {
  return window.matchMedia("(hover: none)").matches
}

function toggleDisableStateOnControl(control, toggled) {
  if (toggled) {
    control.classList.remove(controlsActive)
    control.setAttribute("disabled", toggled)
  } else {
    control.classList.add(controlsActive)
    control.removeAttribute("disabled")
  }
}

export const Slider = ({
  columns,
  items,
  className,
  controls,
  swipe = [true, true, false],
  animate = [true],
  selectedIndex = 0,
}) => {
  const glideRef = React.useRef()
  const sliderRef = React.useRef()
  const prevButtonTopRef = React.useRef()
  const prevButtonBottomRef = React.useRef()
  const nextButtonTopRef = React.useRef()
  const nextButtonBottomRef = React.useRef()

  React.useEffect(() => {
    import(/* webpackChunkname: "glider" */ "./glider.lazy").then(
      ({ Glide, Swipe, Controls }) => {
        if (!sliderRef.current) {
          return
        }

        const shouldAnimate = getViewportValue(animate)
        const glide = new Glide(sliderRef.current, {
          perView: getViewportValue(columns)[0],
          bound: true,
          gap: 20,
          classes: {
            slider: glideSlider,
          },
          startAt: selectedIndex,
          animationDuration: shouldAnimate ? 400 : 0,
        })

        function enableDisableControls() {
          if (prevButtonTopRef.current) {
            toggleDisableStateOnControl(
              prevButtonTopRef.current,
              glide._c.Run.isStart()
            )
          }
          if (prevButtonBottomRef.current) {
            toggleDisableStateOnControl(
              prevButtonBottomRef.current,
              glide._c.Run.isStart()
            )
          }

          if (nextButtonTopRef.current) {
            toggleDisableStateOnControl(
              nextButtonTopRef.current,
              glide._c.Run.isEnd()
            )
          }
          if (nextButtonBottomRef.current) {
            toggleDisableStateOnControl(
              nextButtonBottomRef.current,
              glide._c.Run.isEnd()
            )
          }
        }

        const modules = {}
        if (getViewportValue(controls)) {
          modules.Controls = Controls
          glide.on(["mount.after", "run.after"], enableDisableControls)
        }

        const shouldSwipe = getViewportValue(swipe)
        if (shouldSwipe && hasTouchCapabilities()) {
          modules.Swipe = Swipe
        }

        glideRef.current = glide.mount(modules)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (glideRef.current) {
      glideRef.current.go(`=${selectedIndex}`)
    }
  }, [selectedIndex])

  const normalizedColumns = normalizeValues(columns)

  return (
    <div className={classNames(glide, relative, className)} ref={sliderRef}>
      <div
        className={`${controlsClass} ${textDark} ${controlsClassTop}`}
        data-glide-el="controls"
      >
        <button
          className={controlsButton}
          data-glide-dir="<"
          ref={prevButtonTopRef}
          aria-label="Vorige"
        >
          <PreviousArrow />
        </button>
        <button
          className={controlsButton}
          data-glide-dir=">"
          ref={nextButtonTopRef}
          aria-label="Volgende"
        >
          <NextArrow />
        </button>
      </div>

      <div className={overflowHidden} data-glide-el="track">
        <ul className={`${glideSlides} ${relative}`}>
          {items.map((item) => {
            return (
              <li
                key={item.key}
                className={`${glideSlide} ${normalizedColumns[0][1]} ${normalizedColumns[1][1]} ${normalizedColumns[2][1]}`}
              >
                {item}
              </li>
            )
          })}
        </ul>
      </div>
      <div className={`${controlsClass} ${textDark}`} data-glide-el="controls">
        <button
          className={controlsButton}
          data-glide-dir="<"
          ref={prevButtonBottomRef}
          aria-label="Vorige"
        >
          <PreviousArrow />
        </button>
        <button
          className={controlsButton}
          data-glide-dir=">"
          ref={nextButtonBottomRef}
          aria-label="Volgende"
        >
          <NextArrow />
        </button>
      </div>
    </div>
  )
}
